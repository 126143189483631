import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function Chat() {
  const [conversation, setConversation] = useState([
    {
      sender: 'AIgent',
      text: 'Hi! How can I help with your renovation today? Feel free to chat with me by typing below. I will try to get you a ballpark estimate and assist you in finding the right contractors for your construction needs, big or small.',
    }
  ]);
  const [inputText, setInputText] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userDetails, setUserDetails] = useState({ name: '', email: '', zip: '', phone: '' });
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChatLocked, setIsChatLocked] = useState(false);
  const [token, setToken] = useState('');
  const [conversationId, setConversationId] = useState(Date.now().toString());
  const chatboxRef = useRef(null);
  const formRef = useRef(null);
  const textareaRef = useRef(null); // Reference for the textarea

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [conversation, isThinking]);

  useEffect(() => {
    if (showForm && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showForm]);

  // Auto resize function for the textarea
  const autoResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust the height based on the content
    }
  };

  const handleSend = async (e) => {
    if ((e.key === 'Enter' || e.type === 'click') && inputText.trim() && !isChatLocked) {
      const userMessage = { sender: 'You', text: inputText };
      setConversation([...conversation, userMessage]);
      setIsThinking(true);
      setInputText('');

      try {
        const response = await axios.post(`${API_BASE_URL}/api/chat`, {
          message: inputText,
          conversation: [...conversation, userMessage],
          token: token
        });

        const aiMessage = response.data.message ? { sender: 'AIgent', text: response.data.message } : { sender: 'AIgentx', text: 'I am terribly sorry, something went wrong.' };
        setConversation((prevConversation) => [...prevConversation, aiMessage]);

        if (aiMessage.text.toLowerCase().includes('summarize and send')) {
          setShowForm(true);
          try {
            await axios.post(`${API_BASE_URL}/api/savePreSubmitLog`, {
              conversationId: conversationId,
              sender: 'AIgent',
              message: aiMessage.text,
            });
          } catch (logError) {
            console.error('Error saving pre-submit log:', logError);
            setErrorMessage('Error saving conversation log.');
          }
        }
        setToken(response.data.token);
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Error sending message.');
      } finally {
        setIsThinking(false);
      }
    }
  };

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSendToprospects = async () => {
    const { name, email, zip, phone } = userDetails;

    if (!name || !email || !zip || !phone) {
      setErrorMessage('All fields are required to proceed.');
      return;
    }

    setIsSummarizing(true);
    setErrorMessage('');
    try {
      const summary = conversation.map((msg) => `${msg.sender}: ${msg.text}`).join('\n');
      const response = await axios.post(`${API_BASE_URL}/api/send`, {
        summary,
        userDetails,
        conversation,
      });

      const { success, conversation: finalConversation, lockChat } = response.data;

      if (success) {
        setConversation(finalConversation);
        setIsChatLocked(lockChat);
        setThankYouMessage(true);
      } else {
        setErrorMessage('Failed to send project details.');
      }
    } catch (error) {
      console.error('Error sending project details:', error);
      setErrorMessage('An error occurred while sending project details.');
    } finally {
      setIsSummarizing(false);
    }
  };

  return (
    <div className="Chat">
      <div className="chatbox" ref={chatboxRef}>
        {conversation.map((msg, index) => (
          <div key={index} className={msg.sender === 'You' ? 'user-message' : 'aigent-message'}>
            <strong>{msg.sender}:</strong> {msg.text}
          </div>
        ))}
        {isThinking && <div className="aigent-message">AIgent writing...</div>}
      </div>

      {showForm ? (
        <div className="form-section" ref={formRef}>
          <input type="text" name="name" placeholder="Your Name" value={userDetails.name} onChange={handleChange} />
          <input type="email" name="email" placeholder="Your Email" value={userDetails.email} onChange={handleChange} />
          <input type="text" name="zip" placeholder="Your Zip Code" value={userDetails.zip} onChange={handleChange} />
          <input type="text" name="phone" placeholder="Your Phone Number" value={userDetails.phone} onChange={handleChange} />
          <button className="send-button" onClick={handleSendToprospects} disabled={isChatLocked}>Summarize and Send</button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {isSummarizing && <div className="summarizing-message">Summarizing your conversation...</div>}
          {thankYouMessage && <div className="thank-you-message">Thank you! Your information will be sent to nearby prospects.</div>}
        </div>
      ) : (
<div className="container">
  <textarea
    ref={textareaRef}
    placeholder="Please describe your project."
    value={inputText}
    onChange={(e) => setInputText(e.target.value)}
    onInput={autoResize}
    rows={1}
    style={{ minHeight: '40px', maxHeight: '120px', overflowY: 'hidden' }}
  />
  <button
    onClick={handleSend}
    disabled={isThinking || showForm || isChatLocked}
    className="send-button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-send"
      style={{ width: '30px', height: '30px' }} /* Ensure SVG is appropriately sized */
    >
      <line x1="22" y1="2" x2="11" y2="13" />
      <polygon points="22 2 15 22 11 13 2 9 22 2" />
    </svg>
  </button>
</div>

      )}
    </div>
  );
}

export default Chat;
